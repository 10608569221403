var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cropper"},[_c('div',{staticClass:"crop-actions"},[_c('div',{staticClass:"left-actions"},[(_vm.stencilOrientations)?_c('div',{staticClass:"stencil-orientations"},[_c('button',{staticClass:"landscape btn",on:{"click":function($event){return _vm.changeStencilOrientation('horizontal')}}},[_c('svg',{attrs:{"width":"24","height":"17","viewBox":"0 0 24 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"1","y":"0.5","width":"22","height":"16","rx":"2","stroke":_vm.currentStencilOrientation == 'horizontal' ? '#05A88F' : '#EBEBF5',"stroke-opacity":"0.6"}})])]),_c('button',{staticClass:"portrait btn",on:{"click":function($event){return _vm.changeStencilOrientation('vertical')}}},[_c('svg',{attrs:{"width":"18","height":"23","viewBox":"0 0 18 23","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"1","y":"22.5","width":"22","height":"16","rx":"2","transform":"rotate(-90 1 22.5)","stroke":_vm.currentStencilOrientation == 'vertical' ? '#05A88F' : '#EBEBF5',"stroke-opacity":"0.6"}})])])]):_vm._e()]),_c('div',{staticClass:"right-actions"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.cropper.zoom(0.8)}}},[_c('img',{attrs:{"src":require("@assets/img/icons/zoom-out.svg"),"alt":"Zoom Out"}})]),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.cropper.zoom(1.2)}}},[_c('img',{attrs:{"src":require("@assets/img/icons/zoom-in.svg"),"alt":"Zoom In"}})])])]),_c('cropper-component',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image,"stencil-props":{
            handlers: {},
            movable: false,
            scalable: false,
        },"stencil-size":{
            width: _vm.handleStencilSizeAndOrientations.width,
            height: _vm.handleStencilSizeAndOrientations.height,
        },"image-restriction":"stencil","stencil-component":_vm.stencilType}}),_c('div',{staticClass:"add-image"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.uploadImage}},[_vm._v(" Add Image ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }