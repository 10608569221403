<template>
    <div class="cropper">
        <div class="crop-actions">
            <div class="left-actions">
                <div v-if="stencilOrientations" class="stencil-orientations">
                    <button class="landscape btn" @click="changeStencilOrientation('horizontal')">
                        <svg
                            width="24"
                            height="17"
                            viewBox="0 0 24 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1"
                                y="0.5"
                                width="22"
                                height="16"
                                rx="2"
                                :stroke="currentStencilOrientation == 'horizontal' ? '#05A88F' : '#EBEBF5'"
                                stroke-opacity="0.6"
                            />
                        </svg>
                    </button>
                    <button class="portrait btn" @click="changeStencilOrientation('vertical')">
                        <svg
                            width="18"
                            height="23"
                            viewBox="0 0 18 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1"
                                y="22.5"
                                width="22"
                                height="16"
                                rx="2"
                                transform="rotate(-90 1 22.5)"
                                :stroke="currentStencilOrientation == 'vertical' ? '#05A88F' : '#EBEBF5'"
                                stroke-opacity="0.6"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="right-actions">
                <button type="button" class="btn" @click="cropper.zoom(0.8)">
                    <img src="@assets/img/icons/zoom-out.svg" alt="Zoom Out">
                </button>
                <button type="button" class="btn" @click="cropper.zoom(1.2)">
                    <img src="@assets/img/icons/zoom-in.svg" alt="Zoom In">
                </button>
            </div>
        </div>
        <cropper-component
            ref="cropper"
            class="cropper"
            :src="image"
            :stencil-props="{
                handlers: {},
                movable: false,
                scalable: false,
            }"
            :stencil-size="{
                width: handleStencilSizeAndOrientations.width,
                height: handleStencilSizeAndOrientations.height,
            }"
            image-restriction="stencil"
            :stencil-component="stencilType"
        />
        <div class="add-image">
            <button type="button" class="btn btn-primary" @click="uploadImage">
                Add Image
            </button>
        </div>
    </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
    name: "Cropper",
    components: {
        CropperComponent: Cropper
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        fieldName: {
            type: String,
            default: "file"
        },
        stencilSize: {
            type: Object,
            default() {
                return {
                    width: 300,
                    height: 300
                }
            }
        },
        stencilOrientations: {
            type: Object,
            default: null
        },
        stencilType: {
            type: String,
            default: "rectangle-stencil"
        }
    },
    data() {
        return {
            image: null,
            currentStencilOrientation: "horizontal"
        };
    },
    computed: {
        cropper() {
            return this.$refs.cropper;
        },
        handleStencilSizeAndOrientations() {
            return {
                width: this.stencilOrientations ? this.stencilOrientations[this.currentStencilOrientation].width : this.stencilSize.width,
                height: this.stencilOrientations ? this.stencilOrientations[this.currentStencilOrientation].height : this.stencilSize.height
            }
        }
    },
    created() {
        this.prepareImageToCrop();
    },
    methods: {
        prepareImageToCrop() {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
            };
            reader.readAsDataURL(this.file.data);
        },
        uploadImage() {
            const { canvas } = this.cropper.getResult();
            if (canvas) {
                const formData = new FormData();
                canvas.toBlob(blob => {

                    const file = new File([blob], this.file.name, { type: blob.type })
                    formData.append(this.fieldName, file);
                    formData.append("name", this.file.name);

                    axios.post("/filesystem", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(({ data }) => {
                        this.$emit("uploaded-image", { ...data[0], is_deleted: 0, field_name: this.fieldName });
                        this.$modal.hide("upload-image-modal");
                    }).catch((error) => {
                        console.log(error.response.data.errors.message);
                    })

                }, this.file.type);
            }
        },
        changeStencilOrientation(orientation) {
            this.currentStencilOrientation = orientation;
            this.cropper.zoom(0);
            this.cropper.refresh();
        }
    }
}
</script>

<style lang="scss" scoped>
.cropper {
    min-height: 300px;
    padding: 25px 60px;

    @media(max-width: $md) {
        padding: 15px;
    }

    .crop-actions {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;

        @media(max-width: $md) {
            padding-bottom: 10px;
        }

        .btn {
            padding: 0;

            &:focus {
                box-shadow: none;
            }
        }

        .left-actions,
        .right-actions {
            .btn {
                margin-left: 10px;
            }
            .btn:first-child {
                margin-left: 0;
            }
        }
    }

    .cropper {
        /deep/ .vue-rectangle-stencil,
        /deep/ .vue-circle-stencil {
            outline: 3px solid $primary-color;
        }
    }

    .cropper,
    /deep/ .vue-advanced-cropper__background,
    /deep/ .vue-advanced-cropper__foreground{
        background: #1C1C1E;
    }

    .add-image {
        padding-top: 25px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
